import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';

import { List, ListItem, ListItemText, ListItemAvatar, Grid, IconButton } from '@mui/material';

import _ from "lodash";

import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../ReportsStyles";
import { ArrowBlue, ArrowOrange, DashboardClose } from "../../../assets/images/svgComponents/ImageComponent";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ToolTipComponent from '../../../components/ToolTipComponent/ToolTipComponent';

//Import Constants
import { ReportsConstants } from "../../../config/constants";

const BottomWidgets = (props) => {
    // Get props
    const { classes, adminPreference } = props;
    // Selectors
    const { dashboardData } = useSelector(state => state.dashboard)
    const { loading } = useSelector(state => state.UI)

    // Generate Question By Type
    const generateQuestions = (questType) => {
        let questions = [];

        if (dashboardData.questions.length > 0) {
            const sortedData = dashboardData.questions

            const middleIndex = sortedData.length / 2
            let high = sortedData.slice(0, Math.ceil(middleIndex));
            let low = sortedData.slice(Math.ceil(middleIndex));

            low = _.orderBy(low, [
                function (item) { return item.percentfavorable; },
                function (item) { return item.mean; }
            ], ["asc", "asc"]);

            if (high.length > 5) {
                high = high.slice(0, 5)
            }
            if (low.length > 5) {
                low = low.slice(-5);
            }

            questions = questType === 'Top Rated' ? high : low;

        }

        return questions.map(d =>
            <ListItem >
                <ListItemAvatar>
                    <Grid className={`${questType === 'Top Rated' ? classes.HighRatesCount : classes.LowRatesCount} fontWeight600`}>{`${parseInt(d.percentfavorable)}%`}
                        {questType === "Top Rated" ?
                            <Grid className={classes.RateArrowImgRight}>{ArrowBlue()}</Grid> :
                            <Grid className={classes.RateArrowImgRight}>{ArrowOrange()}</Grid>
                        }
                    </Grid>
                </ListItemAvatar>
                {/* <ListItemText primary={d.questionText} /> */}
                <ListItemText primary={
                    <TypographyComponent variant="p" className='fontSize14 fontweight600'>{d.questionText}</TypographyComponent>
                } />
            </ListItem>
        )
    }

    const handleClose = () => {
        if (typeof adminPreference?.data?.hideDashWidgets?.highLowRatedItems === 'boolean') {
            adminPreference['data']['hideDashWidgets']['highLowRatedItems'] = true;
            adminPreference.updatePreference(adminPreference?.data)
        }
    }

    /* Highest and lowest */
    return dashboardData && dashboardData.totalresponse > 0 && !dashboardData.dashBoardLoaderQuestion && !loading && dashboardData.questions && dashboardData.questions.length > 1 && !adminPreference?.data?.hideDashWidgets?.highLowRatedItems &&
        <Grid container alignItems="stretch" spacing={3} className="mb-2">
            <Grid item className={classes.dashboard_BelowTile}>
                {/* <Grid container alignItems="center" className="mb-15">
                    <Grid item>
                        <TypographyComponent variant="h5" className="txtBold">
                            {ReportsConstants.HIGHESTAND_LOWESTITEMS}</TypographyComponent>
                    </Grid>
                </Grid> */}
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <TypographyComponent variant="h5" className="fontWeight600 borderBottom">
                            {ReportsConstants.Highest}
                        </TypographyComponent>
                        <List className={classes.AlertsList}>
                            {generateQuestions('Top Rated')}
                        </List>
                    </Grid>
                    <Grid item sm={6}>
                        <Grid item className='dflexCenter width100 spacebetween'>
                            <TypographyComponent variant="h5" className="fontWeight600 borderBottom">
                                {ReportsConstants.Lowest}
                            </TypographyComponent>
                            <ToolTipComponent title={ReportsConstants.CLOSE}>
                                <IconButton onClick={handleClose} size="large">
                                    {DashboardClose()}
                                </IconButton>
                            </ToolTipComponent>
                        </Grid>
                        <List className={classes.AlertsList}>
                            {generateQuestions('Lowest Rated')}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
}
// default props
BottomWidgets.defaultProps = {
	adminPreference: {},
};

// prop types
BottomWidgets.propTypes = {
	adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(BottomWidgets);

import { compose, createStore, applyMiddleware } from "redux";
import { logger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import appReducer from "../redux/reducers";
import { authConstant, profileConstant } from "../redux/constants";

const rootReducer = (state, action) => {
  if (action.type === authConstant.LOGOUT) {
    state = undefined;
  } else if (action.type === profileConstant.PROFILE_UPDATE_PREFERENCE_CLIENTID_SUCCESS) {
    state.survey = state.survey.initialState;
    state.metadata = state.metadata.initialState;
    state.people = state.people.initialState;
    state.participant = state.participant.initialState;
    state.admin = state.admin.initialState;
    state.baseQuestions = state.baseQuestions.initialState;
    state.category = state.category.initialState;
    state.dashboard = state.dashboard.initialState;
    state.queryBuilder = state.queryBuilder.initialState;
    state.excel = state.excel.initialState;
    state.spCaller = state.spCaller.initialState;
    state.surveyPreview = state.surveyPreview.initialState;
    state.actionplan = state.actionplan.initialState;
    state.opportunity = state.opportunity.initialState;
    state.report = state.report.initialState;
    state.filter = state.filter.initialState;
    state.externalApiConfig = state.externalApiConfig.initialState;
    state.apiIntegration = state.apiIntegration.initialState;
  } else { }

  return appReducer(state, action);
};

// display logger only in dev enviroment
const isDev = process.env.REACT_APP_BUILD_TO === "LOCAL" || !process.env.REACT_APP_BUILD_TO
  || process.env.REACT_APP_BUILD_TO === "DEV" || process.env.REACT_APP_BUILD_TO === "QA" || process.env.REACT_APP_BUILD_TO === "STACK-QA" || process.env.REACT_APP_BUILD_TO === "QA-LOCAL"
const enhancer = compose(
  applyMiddleware(
    thunkMiddleware,
    ...(isDev ? [logger] : [])
  )
);

export default function store(initialState) {
  return createStore(
    rootReducer,
    initialState,
    enhancer
  );
}
